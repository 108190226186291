<template>
  <div id="home">
    <section class="about">
      <div class="container">
        <div class="image"></div>
        <div class="information">
          <h2>Who this guy is...</h2>
          <p>Following the passion with a greatest hope that it will bring back some real value. <br />
          At least, some meals.</p>
          <p></p>
        </div>
      </div>
    </section>
    <section class="project">
      <div class="information">
        <div class="container">
          <h2>What I had done</h2>
          <p>
            Each project, each client bring up different requirements for their website, which is the top motivations
            for me to keep learning more to satisfy them.
          </p>
          <p>
            But beyond that, satisfying myself by discovering new tectnology and applying them to projects is what make
            me interesting about this job. 
          </p>
          <p>
            And feeling interesting in what you doing is the most important thing for you to keep working!
          </p>
        </div>
      </div>
      <ProjectTable :limit="4" :gridColumn="3"/>
    </section>
    
    <section class="motto">
      <div class="container">
        <div class="image"></div>
        <div class="information">
          <h2>My motto</h2>
            <p><em>Working in IT industry is a long jouney of never stop to become a better me.</em></p>
            <p><em>You may rest, but never give up, <strong>EVER</strong>!</em></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProjectTable from '../components/ProjectTable.vue'
export default {
  name: 'Home',
  components: { ProjectTable },
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
section {
  .container {
    display: grid;
  }
  .information {
    padding: 20px 0;
    text-align: center;
  }
}
section.project {
  background-color: var(--theme-color);
  color: white;
  padding: 20px 0;
}
@media screen and (min-width: 1200px) {
  section.project {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 1px 0;
    .information {
      max-width: 600px;
      margin-left: auto;
      width: 100%;
      text-align: right;
      padding-right: 20px;
      h2 {
        text-align: right;
      }
      p {
        margin-bottom: 25px;
      }
    }
  }
}
</style>
