<template>
    <ul class="project-table container" :class="{
    'loading': is_loading,
    'grid-3': gridColumn === 3,
    'grid-5': gridColumn === 5,
  }">
      <li class="project" v-for="project in filteredProjects" :key="project.name">
        <router-link :to="`/projects/${project.name}`" v-if="project.logo">
          <div class="project-thumb-container has-loading-effect">
              <img class="project-thumb" :src="project.logo" :alt="project.title">
          </div>
          <div class="project-info">
            <p class="project-title has-loading-effect">
              <router-link :to="`/projects/${project.name}`">{{ project.title }}</router-link>
            </p>
            <p class="year has-loading-effect">{{ project.year }}</p>
          </div>
          <div class="hover-effect-border"></div>
          
        </router-link>
      </li>
    </ul>
</template>

<script>
export default {
  name: 'ProjectTable',
  props: {
    limit: {
      type: Number,
      default: 12
    },
    gridColumn: {
      type: Number,
      default: 5
    },
    filter: {
      type: Array,
      default: undefined
    }
  },
  data: function () {
    return {
      is_loading: true,
      projects: [
        { title: '', thumb: '', year: '', uri: 'project-1'},
        { title: '', thumb: '', year: '', uri: 'project-2'},
        { title: '', thumb: '', year: '', uri: 'project-3'},
        { title: '', thumb: '', year: '', uri: 'project-4'}
      ]
    }
  },
  computed: {
    filteredProjects() {
      if (!this.filter || this.filter.length === 0) return this.projects;
      const _this = this;
      return this.projects.filter(item => {
        return item.tags.reduce((r, i) => r || _this.filter.includes(i.id), false)
      })
    }
  },
  created: function () {
    const _this = this;
    let url = `https://me.speakitout.net/api/v1/projects?limit=${this.limit}`
    this.$http.get(url).then(function(response) {
      _this.projects = response.data
      _this.is_loading = false
    })
  },
  methods: {
    loadMore: function () {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h1, h2 {
  text-align: center;
}
ul {
  list-style-type: none;
}
.project {
  .project-thumb-container {
    height: 150px;
    background-color: white;
    padding: 5px;
    &:empty {
      background-color: #e6e6e6;
    }
    img.project-thumb {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .project-title {
    font-weight: bold;
    font-size: 1.2em;
    &:empty {
      height: 18px;
    }
  }
  .year:empty {
    height: 18px;
    width: 30%;
  }
}
.project-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
}

@media screen and (min-width: 1200px) {
  .project-table.container {
    padding: 0;
  }
  .project-table {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    grid-template-rows: auto;
    grid-column-gap: unset;
    grid-row-gap: unset;
    &.grid-3 {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1px;
      grid-row-gap: 1px;
      .project-thumb-container {
        height: 10vw;
      }
    }
    .project {
      position: relative;
      overflow: hidden;
      padding: 10px;
      box-sizing: border-box;
      background-color: white;
      .project-info {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
        transition: all 0.3s;
        color: white;
      }
      .hover-effect-border {
        position: absolute;
        width: 0;
        height: 0;
        border: 0px solid var(--theme-color-light);
        border-top: 0;
        left: 50%;
        bottom: 0;
        box-sizing: border-box;
        &::before, &::after {
          content: "";
          display: block;
          position: absolute;
          box-sizing: border-box;
          width: 0;
          height: 0;
          border: 0px solid var(--theme-color-light);
          top: 0;
        }
        &::before {
          left: 0;
        }
        &::after {
          right: 0;
        }
      }
      &:hover{
        .project-info {
          top: 0;
        }
        .hover-effect-border {
          width: 100%;
          height: 100%;
          left: 0;
          border-bottom-width: 5px; 
          border-left-width: 5px; 
          border-right-width: 5px; 
          transition: border-width 0s, width 0.2s linear, left 0.2s linear, height 0.1s 0.2s linear;
          &::before, &::after {
            width: 51%;
            border-top-width: 5px;
            transition: border-width 0s, width 0.2s 0.3s;
          }
        }
      }
    }
  }
}
</style>
