<template>
  <div id="app">
    <header :class="{ 'home': $route.name == 'home' }">
      <div class="banner-container">
        <div class="banner has-copyright">
          <img src="./assets/images/brand/banner.jpg" alt="">
          <a class="img-copyright" href="https://www.freepik.com/free-photos-vectors/background" target="_blank">Background photo created by lifeforstock - www.freepik.com</a>
        </div>
        <div class="logo">
          <img src="./assets/images/brand/avatar.jpg" alt="Memphis's logo">
        </div>
      </div>
      <nav class="container">
        <ul class="menu">
          <li><router-link :to="'/'"><i class="fas fa-home menu-icon"></i><span class="menu-text">Home</span></router-link></li>
          <li><router-link :to="'/about'"><i class="fas fa-user-circle menu-icon"></i><span class="menu-text">About</span></router-link></li>
          <li><router-link :to="'/career'"><i class="fas fa-briefcase menu-icon"></i><span class="menu-text">Career</span></router-link></li>
          <li><router-link :to="'/projects'"><i class="fas fa-laptop-code menu-icon"></i><span class="menu-text">Projects</span></router-link></li>
          <li><a href="#contact"><i class="fas fa-comments menu-icon"></i><span class="menu-text">Contact</span></a></li>
        </ul>
      </nav>
    </header>
    <router-view></router-view>
    <footer id="contact" tabindex="-1" v-on:focus="onFocus">
      <div class="container">
        <div class="brand">
          <p><img src="./assets/images/brand/signature_w.png" alt="Memphis's signature"></p>
          <p>Copyright &copy; 2019 Memphis Nguyen</p>
        </div>
        <div class="contact">
          <p><a href="mailto:namnguyen.cs13@gmail.com"><i class="fas fa-envelope"></i> namnguyen.cs13@gmail.com</a></p>
          <p><a href="tel:+84903648523"><i class="fas fa-phone"> 0903 648 523</i></a></p>
        </div>
        <div class="social-network">
          <p><a href="https://www.linkedin.com/in/nguy%E1%BB%85n-nam-300178167/" target="_blank" rel="external"><i class="fab fa-linkedin"></i> Nguyễn Nam</a></p>
          <p><a href="https://github.com/MemphisNguyen" target="_blank" rel="external"><i class="fab fa-github"></i> Memphis Nguyen</a></p>
          <p><a href="https://fb.me/nam.nguyenanh.1485537" target="_blank" rel="external"><i class="fab fa-facebook"></i> Nguyễn Anh Nam</a></p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { setTimeout } from 'timers';
export default {
  name: 'app',
  methods: {
    onFocus: function(event){
      setTimeout(function(){
        event.target.removeAttribute("tabindex")
      }, 2000)
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.menu {
  .menu-text {
    display: none;
  }
  .menu-icon {
    font-size: 25px;
    padding: 0 10px;
  }
}
footer .brand img {
  max-width: 100px;
}
@media screen and (min-width: 768px) {
  .menu {
    .menu-text {
      display: initial;
    }
    .menu-icon {
      display: none;
    }
  }
  footer .brand img {
    max-width: 200px;
  }
}
</style>
