import Home from './pages/Home.vue'
import About from './pages/About.vue'
import ProjectArchive from './pages/ProjectArchive.vue'
import Project from './pages/Project.vue'
import Career from './pages/Career.vue'

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/about', name: 'about', component: About },
  { path: '/career', name: 'career', component: Career },
  { path: '/projects', name: 'projects', component: ProjectArchive },
  { path: '/projects/:name', component: Project }
]

export default routes;