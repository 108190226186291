<template>
  <div class="image-viewer-container" :class="{ 'active': isShow}" v-on:keydown="controlByKeyboard($event)" tabindex="-1">
    <div class="image-container">
      <button class="controller" v-on:click="previousImage">
        <i class="fas fa-angle-left"></i>
      </button>
      <div class="image-display" tabindex="-1">
        <img v-for="(data, index) in dataList" :key="index"
          :src="data.thumbnail" alt="" 
          :class="data.class"
          :style="{'transition-duration': `${effectDuration}s`}">
          </div>
      <button class="controller" v-on:click="nextImage">
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
    <div class="title-container" tabindex="-1">
      <p>{{ heading ? `${heading} - ` : "" }}{{ dataList[focusIndex].title }}</p>
    </div>
    <div class="button-container" tabindex="-1">
      <button>
        <a :href="dataList[focusIndex].image_url || dataList[focusIndex].thumbnail" target="_blank"><i class="fas fa-external-link-alt"></i></a>
      </button>
      <button v-on:click="closeViewer">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { Promise } from 'q';
import { setTimeout } from 'timers';
const keyCode = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  ESCAPE: 27,
}
export default {
  name: "ImageViewer",
  props: {
    imageList: {
      type: Array
    },
    heading: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      isShrink: false,
      effectDuration: 0.3,
      dataList: []
    }
  },
  computed: {
    focusIndex () {
      return this.$store.state.viewerimageIndex;
    },
    isShow () {
      if (this.$el){
        this.$el.focus();
      }
      return this.$store.state.viewerIsShow;
    }
  },
  watch: {
    focusIndex() {
      const _this = this
      _this.shrinkImage().then(function() {
        _this.expandImage()
      })
    },
    isShow () {
      window.console.log(this.$el)
    }
  },
  created() {
    this.dataList = this.imageList.map(obj => {
      return {
        ...obj,
        class: {
          "effect_shrink": true,
          "d_none": true
        }
      }
    });
    
    this.dataList[this.focusIndex].class.d_none = false;
    this.dataList[this.focusIndex].class.effect_shrink = false;
  },
  methods: {
    shrinkImage () {
      const _this = this
      return new Promise(function(resolve) {
        _this.dataList.forEach(data => { data.class.effect_shrink = true });
        setTimeout(function(){
          _this.dataList.forEach(data => { data.class.d_none = true });
          resolve()
        }, _this.effectDuration * 1000 / 2)
      })
    },
    expandImage () {
      this.dataList[this.focusIndex].class.d_none = false;
      this.dataList[this.focusIndex].class.effect_shrink = false;
    },
    closeViewer () {
      this.$store.commit('viewerToggleImageViewer');
      this.$store.commit('viewerResetIndex');
    },
    nextImage () {
      this.$store.commit('viewerNextImage', this.imageList.length)
    },
    previousImage () {
      this.$store.commit('viewerPreviousImage', this.imageList.length)
    },
    controlByKeyboard(event) {
      switch (event.keyCode) {
        case keyCode.LEFT:
          this.previousImage()
          break;
        case keyCode.RIGHT:
          this.nextImage();
          break;
        case keyCode.ESCAPE:
          event.target.offsetParent.
          this.closeViewer();
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.image-viewer-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  padding-top: 10px;
  box-sizing: border-box;
  flex-direction: column;
  transition: all 0.3s;
  z-index: 3;
  &.active {
    top: 0;
  }
}
.image-container {
  display: flex;
  align-items: center;
  height: 88%;
  .image-display {
    width: auto;
    height: 100%;
    img {
      transition: transform;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .controller {
    font-size: 20px;
    padding: 10px;
    border: none;
    color: white;
    background: none;
    flex-grow: 1;
    height: 100%;
    &:first-child {
      text-align-last: left;
    }
    &:last-child {
      text-align: right;
    }
  }
}
.title-container p {
  text-align: center;
  color: white;
  font-size: 17px;
  font-weight: bold;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  button {
    width: 40px;
    height: 40px;
    border: 1px solid white;
    color: white;
    font-size: 15px;
    text-align: center;
    background-color: black;
    border-radius: 3px;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}
.effect_shrink {
  transform: scale(0);
}
.d_none {
  display: none;
}
@media screen and (min-width: 1200px){
  .image-container .controller {
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
}
</style>
