import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    viewerimageIndex: 0,
    viewerIsShow: false
  },
  mutations: {
    viewerSelectImage (state, index) {
      state.viewerimageIndex = index
    },
    viewerNextImage (state, length) {
      state.viewerimageIndex += 1
      if (state.viewerimageIndex >= length) {
        state.viewerimageIndex = 0;
      }
    },
    viewerPreviousImage (state, length) {
      state.viewerimageIndex -= 1
      if (state.viewerimageIndex < 0) {
        state.viewerimageIndex = length - 1
      }
    },
    viewerResetIndex (state) {
      state.viewerimageIndex = 0
    },
    viewerToggleImageViewer (state) {
      state.viewerIsShow = ! state.viewerIsShow
    }
  }
});
export default store;