<template>
  <div id="career">
    <h1>My career</h1>
    <section>
      <div class="container">
          <div class="career-container">
              <div class="job" v-for="(job, index) in jobList" :key="index">
                <div class="company-logo">
                  <img :src="job.companyLogo" :alt="job.companyName" />
                </div>
                <div class="job-info">
                  <h2 class="company-name">{{ job.companyName}}</h2>
                  <dl>
                    <dt>Time: </dt>
                    <dd class="job-period">{{ job.period }}</dd>
                    <dt>Position: </dt>
                    <dd class="job-position">{{ job.position }}</dd>
                  </dl>
                  <div class="job-description">{{ job.description }}</div>
                </div>
              </div>
          </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
    name: "MyCareer",
    data() {
        return {
            jobList: [
                {
                    companyName: 'NUS Technology',
                    companyLogo: 'https://me.speakitout.net/storage/uploads/jobs/nus-logo.png',
                    period: 'Jun 2020 - now',
                    position: 'Web developer',
                    description: '',
                },
                {
                    companyName: 'Tiny Software Solution',
                    companyLogo: 'https://me.speakitout.net/storage/uploads/jobs/tinysol.png',
                    period: 'Jun 2019 - Apr 2020',
                    position: 'Web developer',
                    description: '',
                },
                {
                    companyName: 'Cybozu Vietnam',
                    companyLogo: 'https://me.speakitout.net/storage/uploads/jobs/cybozu.png',
                    period: 'Jun 2018 - Aug 2018',
                    position: 'Intern',
                    description: '',
                },
                {
                    companyName: 'EFE Technology',
                    companyLogo: 'https://me.speakitout.net/storage/uploads/jobs/efe.png',
                    period: 'Feb 2017 - Oct 2017',
                    position: 'Web developer',
                    description: '',
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.career-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.job {
    display: grid;
    grid-template-columns: 2fr 5fr;
    margin-bottom: 10px;
    border: 2px solid var(--theme-color);
    border-radius: 5px;
    .company-logo {
      width: 100%;
      background-color: var(--theme-color);
      padding: 5px 10px;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .job-info {
      padding: 5px 10px;
      .company-name {
        text-align: left;
        margin-top: 0;
        margin-bottom: 10px;
      }
      dl dt {
        font-weight: bold;
      }
      dl dd {
        margin-bottom: 5px;
      }
    }
  }
@media screen and (min-width: 992px){
  .career-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

}
</style>