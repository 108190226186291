<template>
  <div class="project-archive page">
    <div class="project-thumbnail-container">
      <div class="project-image" v-for="(image, index) in gallery" v-bind:key="index">
        <img :src="image.thumbnail" :alt="`${title}'s ${image.title}`" v-on:click="showImageViewer(index)">
      </div>
      
    <section id="project-info" class="project-info">
      <div class="project-logo-container loading">
        <img class="project-thumb" :src="thumb" :alt="title">
      </div>
      <h1 class="project-title">Project {{ title }}</h1>
      <div class="project-year">
        <p class="year">- {{ year }} -</p>
      </div>
      <div class="project-tags">
        <Chip v-for="item in tags" :key="item.id" :name="item.name"
        :icon="item.icon" :active="item.active" v-on:click.native="toggleFilter(item)"/>
      </div>
      <div class="project-link">
        <p class="link"><label>Link:</label> <a :href="link" target="_blank">{{ link }}</a></p>
      </div>
      <div class="description-container" v-html="nl2p(description)"></div>
    </section>
    </div>
    <section id="project-image">
      <ImageViewer v-if="gallery" :imageList="gallery" :heading="title"/>
    </section>
  </div>
</template>

<script>
import ImageViewer from '../components/ImageViewer.vue'
import Chip from '../components/Chip.vue'

export default {
  name: 'Project',
  components: { ImageViewer, Chip },
  data: function () {
    return {
      title: this.$route.params.name.substr(0, 1).toUpperCase() + this.$route.params.name.replace('-', ' ').substr(1),
      description: '',
      thumb: '',
      year: '',
      gallery: null,
      link: '',
      tags: [],
    }
  },
  created: function () {
    const _this = this;
    let url = `https://me.speakitout.net/api/v1/projects/${this.$route.params.name}`
    this.$http.get(url).then(function(response){
      let data = response.data;
      _this.title = data.title
      _this.description = data.description == null ? '' : data.description
      _this.thumb = data.logo
      _this.year = data.year
      _this.gallery = data.gallery
      _this.link = data.link
      _this.tags = data.tags
    })
  },
  methods: {
    nl2p (text) {
      let html = text.replace(/\n/gm, '</p><p>')
      return `<p>${html}</p>`
    },
    showImageViewer (index) {
      this.$store.commit('viewerSelectImage', index)
      this.$store.commit('viewerToggleImageViewer')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h1{
  margin-bottom: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0 10px;
}
a {
  color: #42b983;
}
label {
  font-weight: bold;
}
.project-info {
  grid-column: 1 / span 2;
  grid-row-start: 1;
  padding: 0 15px;
  .year {
    font-style: italic;
    text-align: center;
  }
  .project-tags {
    text-align: center;
  }
}
.project-logo-container {
  height: 150px;
  .project-thumb {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.project-thumbnail-container {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, calc(50vw - 1px));
  grid-template-rows: auto;
  .project-image {
    border: 1px solid var(--theme-color);
    height: calc(50vw - 1px);
    justify-items: stretch;
    align-items: stretch;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (min-width: 1200px){
  .project-info {
    grid-column-start: 3;
  }
  .project-logo-container img {
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  .project-thumbnail-container {
    grid-template-columns: repeat(6, 1fr);
    align-items: end;
    .project-image {
      height: 300px;
    }
  }
}
</style>
