<template>
  <div id="about">
    <h1>My milestone</h1>
    <section>
      <div class="container">
        <div class="milestone-container">
          <div class="milestone" v-for="(value, key) in milestones" :key="key">
            <div class="icon">
              <span class="box">
                <i :class="value.icon"></i>
              </span>
            </div>
            <div class="content">
              <h3 class="title">{{ value.title }}</h3>
              <div class="time">- {{ value.time }} -</div>
              <div class="description" v-html="value.description"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      milestones: [
        {
          title: "Birthday",
          time: "Feb, 1995",
          description:
            `<p>A creature was brought to the life with a simple hope to had a good life.</p>
            <p>Unfortunately, that creature has never mean to had a simple one.</p>`,
          icon: "fas fa-birthday-cake"
        },
        {
          title:"Love at first touch",
          time: "2005",
          description:
            `<p>First, addicted with the game.</p>
            <p>Then, addicted with things that a computer can do.</p>
            <p>And finally, be amazed by what I could make it do.</p>
            <br>
            <p>MS-DOS cmd, Pascal, the very basic things, that's where I start my career in the very beginning, 4th grade.</p>`,
          icon: "fas fa-desktop"
        },
        {
          title:"First program",
          time: "2010",
          description:
            `<p>First program? Must be "Hello World!".</p>
            <p>Not wrong, but that's not what I mean to.
            The one I mentioned must be worth to share, worth to show.</p>
            <p>It's n-equation solver, which written in Pascal with an algorithm that read from a Russian book.</p>
            <p>I don't know Russian, I don't event know if the algorithm I collected was right or wrong.
            But that's when I realized my potentials: ability to predict the right, to fomularize to generalization.</p>
            <p>What a precious gift for a developer!</p>
            `,
          icon: "fas fa-code"
        },
        {
          title:"Grow the seed",
          time: "2013",
          description:
            `Satisfying with what I currently have is not my style, so university is my option to know more about
            Information Technology world and HCMC Bach Khoa University is my choice to get what I need.`,
          icon: "fas fa-book-reader"
        },
        {
          title:"Start to fly",
          time: "2019",
          description:
            `Finished university in 2019, it took longer than I thought, I choose to work at Tiny Software Solution.
            Eventhough Tinysol is a brand new name but I believe this is the land of chances, chance to gain experience
            chance to have a big step in my career, chance to get back everything I had been lated for all last years. `,
          icon: "fas fa-plane-departure"
        },
      ]
    };
  }
};
</script>
<style scoped lang="scss">
.milestone {
  margin: 35px 0 50px 0;
  .icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .box {
      font-size: 20px;
      color: white;
      background-color: var(--theme-color);
      width: 50px;
      height: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-10px) rotate(45deg);
      > * {
        transform: rotate(-45deg);
      }
    }
    &::before, &::after {
      content: "";
      display: inline-block;
      width: 65px;
      height: 25px;
      border: 6px solid var(--theme-color);
      border-top: transparent;
      box-sizing: border-box;
    }
    &::before {
      border-left: 15px solid transparent;
      transform: translateX(10px);
    }
    &::after {
      border-right: 15px solid transparent;
      transform: translateX(-10px);
    }
    
  }
  .title {
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .time {
    font-style: italic;
    text-align: center;
  }
  .description {
    margin-top: 5px;
    text-align: center;
  }
}
@media screen and (min-width: 1200px){
  .milestone-container {
    margin: 0 10% 0 20%;
    width: 70%;
  }
  .milestone {
    position: relative;
    padding-bottom: 30px;
    margin: unset;
    &:not(:last-child){
      &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        right: calc(100% + 55px);
      }
      &::before {
        width: 5px;
        height: 100%;
        background-color: var(--theme-color);
        transform: translateX(50%);
      }
      &::after {
        width: 15px;
        height: 15px;
        border: 5px solid var(--theme-color);
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        top: 100%;
        transform: translateX(50%) rotate(45deg);
      }
    }
    .icon {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      position: absolute;
      right: calc(100% + 30px);
      .box {
        transform: rotate(45deg)
      }
      &::before, &::after {
        content: unset;
      }
    }
    .title {
      margin-bottom: 0;
      text-transform: uppercase;
      text-align: left;
    }
    .time {
      text-align: unset;
    }
    .description {
      text-align: unset;
    }
  }
}
</style>
