<template>
  <div class="project-archive">
    <h1 v-if="$route.name=='projects'">Projects</h1>
    <div class="project-filter">
      <Chip v-for="item in tags" :key="item.id" :name="item.name"
       :icon="item.icon" :active="item.active" v-on:click.native="toggleFilter(item)"/>
    </div>
    <div class="project-archive-container">
        <ProjectTable :filter="filter"/>
    </div>
  </div>
</template>

<script>
import ProjectTable from "../components/ProjectTable.vue"
import Chip from "../components/Chip.vue"
export default {
  name: 'ProjectArchive',
  components: { ProjectTable, Chip },
  data() {
    return {
      tags: [],
      filter: [],
    }
  },
  created() {
    const _this = this;
    let url = `https://me.speakitout.net/api/v1/tags`
    this.$http.get(url).then(function(response){
      _this.tags = response.data.map(item => {
        item.active = false
        return item
      })
    })
  },
  methods: {
    toggleFilter(item) {
      item.active = !item.active;
      if (this.filter.includes(item.id)) {
        this.filter.splice(this.filter.indexOf(item.id), 1)
      } else {
        this.filter.push(item.id)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.project-archive-container {
    margin-bottom: 10px;
}
.project-filter {
  margin-bottom: 10px;
  text-align: center;
}
</style>
