<template>
  <span class="chip" :class="{'active': active}" clickable>
    <span class="icon" v-if="icon">
      <img :src="icon" alt srcset />
    </span>
    <span class="chip-name">{{ name }}</span>
  </span>
</template>
<script>
export default {
  name: "Chip",
  props: {
    name: String,
    icon: {
      type: String,
      default: undefined
    },
    active: {
      type: Boolean,
      default: false,
    }
  }
};
</script>
<style lang="scss">
.chip {
  border-radius: 50px;
  padding: 5px 10px 5px 5px;
  background-color: #e3e3e3;
  margin-right: 10px;
  font-weight: bold;
  display: inline-flex;
  margin-bottom: 5px;
  &:last-child {
    margin-right: unset;
  }
  &.active {
    color: white;
    background-color: var(--theme-color);
  }
  .icon {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    overflow: hidden;
    display: inline-block;
    background-color: white;
    margin-right: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
